import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { askQuestion } from '../api';
import { FaPaperPlane, FaRobot, FaUser, FaDownload } from 'react-icons/fa';
import { useTable } from 'react-table';
import { Box, Typography, TextField, Select, MenuItem, Button, Paper } from '@mui/material';

function CsvTable({ csvContent }) {
  const csvRows = useMemo(() => csvContent.trim().split('\n').map(row => row.split(',')), [csvContent]);
  const headers = useMemo(() => csvRows[0], [csvRows]);
  const data = useMemo(() => csvRows.slice(1), [csvRows]);

  const columns = useMemo(
    () => headers.map((header, index) => ({
      Header: header,
      accessor: `col${index}`,
    })),
    [headers]
  );

  const tableData = useMemo(
    () => data.map(row => {
      const rowData = {};
      row.forEach((cell, index) => {
        rowData[`col${index}`] = cell;
      });
      return rowData;
    }),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: tableData });

  return (
    <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ padding: '8px', borderBottom: '2px solid #ddd' }}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}

function ChatSection({ sessionId, showNotification }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [mode, setMode] = useState('fast');
  const [isLoading, setIsLoading] = useState(false);
  const chatDisplayRef = useRef(null);

  useEffect(() => {
    setMessages([]);
  }, [sessionId]);

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = { text: input, sender: 'user' };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await askQuestion(sessionId, input, mode);
      const aiMessage = { 
        text: response.text, 
        sender: 'ai',
        generatedFiles: response.generatedFiles
      };
      setMessages(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Error asking question:', error);
      setMessages(prev => [...prev, { text: 'Error: Unable to get response', sender: 'system' }]);
      showNotification('Failed to get AI response', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = useCallback((fileType, fileName, content) => {
    let blob;
    let fileExtension;

    if (fileType === 'image') {
      blob = base64ToBlob(content, 'image/png');
      fileExtension = 'png';
    } else if (fileType === 'csv') {
      blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
      fileExtension = 'csv';
    } else {
      console.error('Unsupported file type');
      return;
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.${fileExtension}`;
    link.click();
    URL.revokeObjectURL(link.href);
  }, []);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const renderGeneratedFiles = useCallback((generatedFiles) => {
    if (!generatedFiles) return null;

    return (
      <Box sx={{ mt: 2 }}>
        {Object.entries(generatedFiles.images || {}).map(([filename, base64Data], index) => (
          <Box key={`img-${index}`} sx={{ mb: 2 }}>
            <img 
              src={`data:image/png;base64,${base64Data}`} 
              alt={`Generated content ${index + 1}`}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
            <Button 
              variant="contained"
              startIcon={<FaDownload />}
              onClick={() => handleDownload('image', filename, base64Data)}
              sx={{ mt: 1 }}
            >
              Download
            </Button>
          </Box>
        ))}
        {Object.entries(generatedFiles.csvs || {}).map(([filename, csvContent], index) => (
          <Box key={`csv-${index}`} sx={{ mb: 2 }}>
            <Typography variant="h6">{filename}</Typography>
            <CsvTable csvContent={csvContent} />
            <Button 
              variant="contained"
              startIcon={<FaDownload />}
              onClick={() => handleDownload('csv', filename, csvContent)}
              sx={{ mt: 1 }}
            >
              Download
            </Button>
          </Box>
        ))}
      </Box>
    );
  }, [handleDownload]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Chat: {sessionId}</Typography>
      <Paper 
        elevation={3} 
        ref={chatDisplayRef}
        sx={{ 
          flexGrow: 1, 
          overflowY: 'auto', 
          p: 2, 
          mb: 2, 
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {messages.length === 0 ? (
          <Typography sx={{ alignSelf: 'center', justifySelf: 'center', color: 'text.secondary' }}>
            No messages yet. Start a conversation!
          </Typography>
        ) : (
          messages.map((msg, index) => (
            <Box 
              key={index} 
              sx={{ 
                display: 'flex', 
                justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                mb: 2
              }}
            >
              <Paper 
                elevation={1}
                sx={{ 
                  p: 2, 
                  maxWidth: '70%', 
                  backgroundColor: msg.sender === 'user' ? 'primary.light' : 'grey.100',
                  textAlign: msg.sender === 'ai' ? 'left' : 'right'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                  {msg.sender === 'user' ? <FaUser /> : <FaRobot />}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {msg.sender === 'user' ? 'You' : 'AI'}
                  </Typography>
                </Box>
                {msg.sender === 'ai' ? (
                  <>
                    <ReactMarkdown>{msg.text}</ReactMarkdown>
                    {renderGeneratedFiles(msg.generatedFiles)}
                  </>
                ) : (
                  <Typography>{msg.text}</Typography>
                )}
              </Paper>
            </Box>
          ))
        )}
        {isLoading && (
          <Box sx={{ alignSelf: 'center', justifySelf: 'center' }}>
            <Typography>Thinking...</Typography>
          </Box>
        )}
      </Paper>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 1 }}>
        <TextField
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter your query here"
          disabled={isLoading}
          variant="outlined"
          size="small"
        />
        <Select
          value={mode}
          onChange={(e) => setMode(e.target.value)}
          disabled={isLoading}
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="fast">Fast</MenuItem>
          <MenuItem value="thorough">Thorough</MenuItem>
        </Select>
        <Button 
          type="submit" 
          variant="contained" 
          disabled={isLoading}
          startIcon={<FaPaperPlane />}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
}

export default ChatSection;