import axios from 'axios';

const API_BASE_URL = 'https://bim-dev-ugndxog6aq-nw.a.run.app';
// const API_BASE_URL = 'http://localhost:8080';

const api = axios.create({
  baseURL: API_BASE_URL,
});

const handleApiError = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message || 'An error occurred');
  } else if (error.request) {
    throw new Error('No response from server. Please check your internet connection.');
  } else {
    throw new Error('An unexpected error occurred');
  }
};

export const loginUser = async (email, password) => {
  try {
    const response = await api.post('/login', { email, password });
    if (response.data.token) {
      localStorage.setItem('id_token', response.data.token);
    }
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const validateToken = async () => {
  try {
    const token = localStorage.getItem('id_token');
    const response = await api.post('/validate_token', null, {
      headers: { Authorization: `${token}` }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getSessions = async () => {
  try {
    const token = localStorage.getItem('id_token');
    const response = await api.post('/get_sessions', null, {
      headers: { Authorization: `${token}` }
    });
    return {
      sessions: response.data.sessions.map(session => ({
        session_id: session,
        is_new: false
      }))
    };
  } catch (error) {
    handleApiError(error);
  }
};

export const askQuestion = async (sessionId, question, mode) => {
  try {
    const token = localStorage.getItem('id_token');
    const response = await api.post('/ask_question', 
      { session_id: sessionId, question, mode },
      { headers: { Authorization: `${token}` } }
    );
    return {
      text: response.data.response,
      generatedFiles: response.data.generated_files
    };
  } catch (error) {
    handleApiError(error);
  }
};

export const getSessionDocuments = async (sessionId) => {
  try {
    const token = localStorage.getItem('id_token');
    const response = await api.post('/get_session_documents', 
      { session_id: sessionId },
      { headers: { Authorization: `${token}` } }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const uploadDocuments = async (sessionId, files) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
  formData.append('data', JSON.stringify({ session_id: sessionId }));

  try {
    const token = localStorage.getItem('id_token');
    const response = await api.post('/upload_pdfs', formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        Authorization: `${token}`
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};