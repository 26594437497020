import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import ChatSection from './ChatSection';
import DocumentSection from './DocumentSection';
import Notification from './Notification';
import { validateToken } from '../api';
import { Box, Typography, ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../theme';

function MainUI() {
  const [currentSession, setCurrentSession] = useState(() => {
    const lastSessionId = localStorage.getItem('lastSession');
    return lastSessionId ? { session_id: lastSessionId, is_new: false } : null;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await validateToken();
        setIsLoading(false);
      } catch (error) {
        console.error('Authentication error:', error);
        navigate('/');
      }
    };
    checkAuth();
  }, [navigate]);

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      localStorage.removeItem('id_token');
      localStorage.removeItem('lastSession');
      navigate('/');
    }
  };

  const handleSessionSelect = (session) => {
    console.log('Session selected:', session);
    setCurrentSession(session);
    localStorage.setItem('lastSession', session.session_id);
  };

  const showNotification = (message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(notif => notif.id !== id));
  };

  const handleCreateSession = (newSession) => {
    setCurrentSession(newSession);
    localStorage.setItem('lastSession', newSession.session_id);
    showNotification('New session created', 'success');
  };

  if (isLoading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography>Loading...</Typography>
    </Box>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Header onLogout={handleLogout} />
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', height: 'calc(100vh - 80px)' }}>
          <Sidebar 
            onSessionSelect={handleSessionSelect} 
            showNotification={showNotification} 
            currentSession={currentSession}
            onLogout={handleLogout}
            onCreateSession={handleCreateSession}
          />
          <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
            {currentSession ? (
              <>
                <ChatSection sessionId={currentSession.session_id} showNotification={showNotification} />
                <DocumentSection session={currentSession} showNotification={showNotification} />
              </>
            ) : (
              <Typography sx={{ textAlign: 'center', mt: 4 }}>
                Select a session or create a new session to begin
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ position: 'fixed', top: 80, right: 20, zIndex: 1000 }}>
          {notifications.map(notif => (
            <Notification
              key={notif.id}
              message={notif.message}
              type={notif.type}
              onClose={() => removeNotification(notif.id)}
            />
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MainUI;