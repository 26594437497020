import React, { useState, useEffect, useRef, useCallback } from 'react';
import { uploadDocuments, getSessionDocuments } from '../api';
import { FaUpload, FaFile } from 'react-icons/fa';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

function DocumentSection({ session, showNotification }) {
  const [documents, setDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const loadDocuments = useCallback(async () => {
    if (!session || session.is_new) return;

    try {
      const docsData = await getSessionDocuments(session.session_id);
      setDocuments(docsData.documents || []);
    } catch (error) {
      console.error('Error loading documents:', error);
      showNotification('Failed to load documents', 'error');
    }
  }, [session, showNotification]);

  useEffect(() => {
    if (session && !session.is_new) {
      loadDocuments();
    } else {
      setDocuments([]);
    }
  }, [session, loadDocuments]);

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length === 0) return;

    setIsUploading(true);
    try {
      if (!session || !session.session_id) {
        throw new Error('No active session');
      }
      await uploadDocuments(session.session_id, files);
      await loadDocuments();
      showNotification('Documents uploaded successfully', 'success');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error uploading documents:', error);
      showNotification('Failed to upload documents', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>Documents</Typography>
      <Box sx={{ mb: 2 }}>
        <input
          accept="*/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileUpload}
          disabled={isUploading || !session}
          ref={fileInputRef}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="span"
            startIcon={<FaUpload />}
            disabled={isUploading || !session}
          >
            Upload Documents
          </Button>
        </label>
      </Box>
      {isUploading && <Typography sx={{ my: 1 }}>Uploading...</Typography>}
      {documents.length > 0 ? (
        <List>
          {documents.map((doc, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <FaFile />
              </ListItemIcon>
              <ListItemText primary={doc} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No documents uploaded yet.</Typography>
      )}
    </Box>
  );
}

export default DocumentSection;